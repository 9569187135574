import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,

} from "react-router-dom";
import Navbar from './components/Navbar';
import Header from './components/Header';
import Homepage from './components/Homepage'
import ScrollHandler from './components/ScrollHandler';
import Card from './components/Card'
import SliderPart from './components/SliderPart';
import NewsPage from './components/NewsPage';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import '../src/App.css'
import Partner from "./images/partnership.PNG";
import '@fortawesome/fontawesome-free/css/all.min.css';
import Footer from './components/Footer';
import Card2 from './components/Card2';
import About from './components/Pages/About';
import Tours from './components/Pages/Tours';
import TourAndijan from './components/Tours/TourAndijan';
import TourNamangan from './components/Tours/TourNamangan';
import TourFergana from './components/Tours/TourFergana';
import TourTashkent from './components/Tours/TourTashkent';
import TourKazak from './components/Tours/TourKazak';
import TourKirgiz from './components/Tours/TourKirgiz';
import TourTadjik from './components/Tours/TourTadjik';
import Gallery from './components/Pages/Gallery';
import ToursPage from './components/Pages/TourPages';
import News from './components/Pages/News';
import Contact from './components/Pages/Contact';
import Testimonial from './components/Testimonial';
import NewsPages from './components/Pages/NewsPages';
import PrivacyPolicy from "./components/NewPage/PrivacyPolicy";
import AboutUzbekistan from "./components/NewPage/AboutUzbekistan";
import Mission from "./components/NewPage/Mission";
import Tickets from "./components/NewPage/Tickets";
import ForTourist from "./components/NewPage/ForTourist";



function Home() {
  return <div className="App">
    <Homepage />
    <div className='mission-container'>
      <h1 className='mission-title'>Партнер Travelife</h1>
      <p className='mission-text'>
        Награда Travelife Partner — признание нашей приверженности социальным и экологическим стандартам устойчивого развития.
        Мы соблюдаем более 100 критериев, связанных с управлением устойчивостью, офисными операциями, работой с поставщиками и коммуникацией с клиентами.
        Мы стремимся к дальнейшему улучшению с целью в конечном итоге достичь стадии Travelife Certified.
        Travelife — это трехступенчатая программа сертификации для туроператоров и туристических агентов: (1) Travelife Engaged; (2) Travelife Partner и (3) Travelife Certified.
        Мы находимся на стадии 2 – Travelife Partner. Мы работаем над тем, чтобы продвинуться к стадии 3 - Travelife Certified.
        Подробнее о Travelife
      </p><br />

      <img src={Partner} alt='' className='partner-img' />
    </div>
    <Card />
    <Card2 />
    <SliderPart />
    <NewsPage />
    <Testimonial />
    <Footer />
  </div>;
}

function App() {
  return (
    <Router>
      <ScrollHandler>
        <Navbar />
      </ScrollHandler>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/tours" element={<Tours />} />
        <Route path="/andijan" element={<TourAndijan />} />
        <Route path="/namangan" element={<TourNamangan />} />
        <Route path="/fergana" element={<TourFergana />} />
        <Route path="/tashkent" element={<TourTashkent />} />
        <Route path="/kazakhstan" element={<TourKazak />} />
        <Route path="/kirgizistan" element={<TourKirgiz />} />
        <Route path="/tadjikistan" element={<TourTadjik />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/tours/:id" element={<ToursPage />} />
        <Route path="/item/:id" element={<NewsPages />} />
        <Route path="/news" element={<News />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/about-uzbekistan" element={<AboutUzbekistan />} />
        <Route path="/tickets" element={<Tickets />} />
        <Route path="/mission" element={<Mission />} />
        <Route path="/for-tourists" element={<ForTourist />} />
      </Routes>
    </Router>
  );
}



export default App;
